<template>
  <section class="py-6 py-lg-8 overflow-hidden">
    <div class="container">
      <div class="px-lg-8 px-5 d-flex flex-column">
        <h2 class="content-title fs-1 text-center mb-8">主要股東名單</h2>
        <div class="w-100 table-responsive table__container">
          <table class="table table-striped table-hover text-nowrap mb-0">
            <thead>
              <tr>
                <th
                  v-for="(col, idx) in table.thead"
                  :key="idx"
                  :class="col.class"
                  :style="col.style"
                >
                  {{ col.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in table.tbody" :key="row.name">
                <td
                  v-for="col in table.thead"
                  :class="col.class"
                  :style="col.style"
                  :key="col.type"
                >
                  {{ row[col.type] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="mt-3 ms-auto">{{ updateDate }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { apiQueryList } from "@/api/shareholder";
export default {
  data() {
    return {
      updateDate: "暫無日期",
      table: {
        thead: [
          { type: "name", name: "主要股東名稱/股份" },
          { type: "stockNum", name: "持有股數", class: "text-end" },
          { type: "stockRatio", name: "持股比例", class: "text-end" },
        ],
        tbody: [],
      },
    };
  },
  methods: {
    async queryData() {
      const res = await apiQueryList({ page: 1, size: 10 });
      const {
        data: { data },
      } = res;
      this.table.tbody = data.content;
      const [year, month, day] = data.updateDate.split("-");
      this.updateDate = `${year}年${month}月${day}日`;
    },
  },
  created() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped>
.table__container {
  overflow-x: auto;
  & > table {
    min-width: 30rem;
    th:not(:first-child):not(:last-child),
    td:not(:first-child):not(:last-child) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
</style>
